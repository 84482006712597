<style lang="scss">
.martkplatz-bildung-beruf {
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }

  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
    h2 {
    }
    .bild-box {
      img {
        display: block;
        margin: 0 auto;
      }
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
  }
  .content-link-mehrinfo {
    color: #fff !important;
  }
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .custom-margin-top {
    margin-top: 63px !important;
    @media (max-width: $width-lg) {
    }
  }
  .alignItems {
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
  }

  .tab-group-container {
    .title-container {
      @media (max-width: 1350px) {
        flex-wrap: wrap;
        align-items: stretch;
      }
      .title {
        @media (max-width: 1350px) {
          flex: 1 0 25%;
          display: inline-block;
        }
        @media (max-width: 1200px) {
          flex: 0 33.33%;
          display: inline-block;
        }
        @media (max-width: 992px) {
          flex: 0 100%;
          display: block;
        }
      }
    }
  }
  .download-pdf {
    a {
      max-width: 100%;
    }
  }
}
.collapsible-container {
  a {
    color: $blue !important;
  }
  img {
    margin: 15px auto;
    max-width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    padding: 10px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>

<template>
  <div class="martkplatz-bildung-beruf content container">
    <h1>Bei uns finden sich die Besten!</h1>
    <p class="intro-txt">Die besten Unternehmen der Region. Die besten Mitarbeiter in der Region. Jeden Mittwoch und Samstag in der Kleinen Zeitung und auf kleinezeitung.at/karriere.</p>
    <p><strong>Vom klassischen Job-Inserat bis hin zum umfangreichen Employer-Branding-Konzept:</strong> Wir bieten Ihnen die beste Lösung für Ihren Kommunikationsbedarf.</p>

    <h2>Unsere Werbelösungen für Beruf &amp; Bildung</h2>

    <TabGroup>
      <Tab tabid="berufbildung-tab1" :title="'Raum- und Zeilenanzeigen'" active>
        <div class="row margin-tb-s">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h2>Raum- und Zeilenanzeigen</h2>
            <p>Offene Stellen sollen idealerweise schnell und langfristig besetzt werden. Wir helfen Ihnen, die richtigen Mitarbeiter zu finden.</p>
            <p><strong>Anzeigenschluss</strong><br />Donnerstag, 15 Uhr für Samstag<br />Dienstag, 10 Uhr für Mittwoch</p>
            <p><strong>Wiederholungsschaltung Raumanzeigen</strong> <br />50 % auf den Normaltarif bei Schaltung des gleichen Sujets wie bei Erstschaltung innerhalb von 14 Tagen.</p>
            <p><strong>Wiederholungsschaltung Zeilenanzeigen</strong><br />50 % auf den Normaltarif bei Schaltung des gleichen Sujets wie bei Erstschaltung innerhalb von 7 Tagen nur innerhalb des Hauptmarktes (Samstag) und Zweitmarktes (Mittwoch) möglich.</p>
            <p></p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content/branchenloesungen/prod-beruf-bildung.png" alt="Raum und Zeilenanzeigen" class="img-fluid img-center" />
          </div>
        </div>

        <div class="row justify-content-center hinweis-box  margin-tb-m">
          <div class="col-lg-2 bg-left align-self-center"><span class="material-icons">lightbulb</span></div>
          <div class="col-lg-10 bg-right align-self-center">
            <p><strong>Ihr Vorteil!</strong> Alle Raumanzeigen ab der Größe einer 1/8 Seite werden zusätzlich für 4 Wochen als Job-Ticker ausgespielt.</p>
          </div>
        </div>

        <!-- TABELLEN -->
        <!--<div class="tabelle-box tabelle-width-50">
          <div class="headline">
            <strong>Millimeterpreis 4C</strong>
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-right">Kombination</div>
            <div class="col-lg-4 text-align-right">Steiermark</div>
            <div class="col-lg-4 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-4 col-6 text-align-right">21,88</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-4 col-6 text-align-right">15,48</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-4 col-6 text-align-right">9,47</div>
          </div>
        </div>-->
        <div class="text-center">
          <CollapsibleButton link="raum-zeilenanzeigen-tabellen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Ihre Werbemöglichkeiten</CollapsibleButton>
        </div>
        <Collapsible link="raum-zeilenanzeigen-tabellen">
          <h2>Raumanzeigen</h2>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Millimeterpreise </strong>
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>

                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">22,21</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">15,71</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">9,61</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3"></div>
          </div>

          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/8 Seite</strong>
                  Fixformat: 47 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>

                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">2.998,25</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">2.120,85</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">1.297,35</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/4 Seite</strong>
                  Fixformat: 98 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5.996,70</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">4.241,70</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">2.594,70</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/2 Seite</strong>
                  Fixformat: 200 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">11.993,40</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">8.483,40</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">5.189,40</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/1 Seite</strong>
                  Fixformat: 200 x 275 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">24.431,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">17.281,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">10.571,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x135" class="img-fluid " />
            </div>
          </div>

          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Rubrikenvorspann</strong>
                  Format: 200 x 110 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">2.050,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">1.550,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">950,00</div>
                </div>
                <div class="info-txt-tabelle">Erscheinung nur mittwochs möglich.<br />Pro Mittwochausgabe nur ein Rubrikenvorspann verfügbar.<br />PR-Produktionskosten im Tarif enthalten.</div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x110.svg" alt="200x110" class="img-fluid " />
            </div>
          </div>
          <div class="margin-tb-m"></div>
          <h2>Zeilenanzeigen</h2>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Top-Job der Woche</strong>
                  Fixformat: 90 x 40mm (inkl. Rahmen: 98 x 70 mm)
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>

                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">1.410,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">1.005,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">595,00</div>
                </div>
                <div class="info-txt-tabelle">Erscheinung nur samstags. Nur nach Verfügbarkeit buchbar (reservierungspflichtig)</div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-90x40.svg" alt="90x40" class="img-fluid " />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 justify-content-center align-self-center">
              <div class="tabelle-box alignItems">
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">1. Zeile</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">20,91</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">13,21</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">10,01</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Zusatzzeile</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">16,29</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">10,61</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">7,84</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Logo 4c</span> je Zeile</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">16,73</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">13,21</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">9,68</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Foto</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">66,21</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">40,33</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">37,15</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Icon</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">3,25</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">3,25</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">3,25</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Online-Code</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">5,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">5,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 justify-content-center align-self-center">
              <img src="@/assets/img/img-content//branchenloesungen/bsp-beruf-bildung.png" alt="Bsp. Beruf und Bildung" class="img-fluid" />
              <div class="text-center">
                <small>UMSETZUNGSBEISPIEL</small>
              </div>
            </div>
          </div>
          <div class="margin-tb-m"></div>
          <h2>Top-Unternehmen der Woche</h2>
          <div class="portfolio-box">
            <div class="row">
              <div class="col-lg-6">
                <h2>Leistungen &amp; Tarif</h2>
                <ul class="content-ul">
                  <li>Exklusivität – nur ein Unternehmen pro Erscheinungstermin</li>
                  <li>Qualitativer Content – Erstellung durch unsere PR-Redaktion</li>
                  <li>Digitale Präsenz auf kleinezeitung.at und in den sozialen Netzwerken</li>
                  <li>Platzierung im Kleine Zeitung Karriere-Umfeld</li>
                </ul>
              </div>
              <div class="col-lg-6"></div>
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Top-Unternehmen der Woche </strong>
                  Format: 1 Seite 200 x 275 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>

                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">SA</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">14.719,50</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">11.144,50</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">7.789,50</div>
                </div>
                <div class="info-txt-tabelle">Erscheinung nur samstags. Inklusive digitale Umsetzung: #KLZpräsentiert</div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
            </div>
          </div>
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer.</div>
        </Collapsible>
      </Tab>
      <Tab tabid="bildungberuf-tab4" :title="'Job-Ticker'">
        <div class="portfolio-box">
          <div class="row">
            <div class="col-lg-6">
              <h2>Job-Ticker</h2>

              <p>Ihre <strong>digitale Job-Zeilenanzeige als „News-Ticker“</strong> mit aktuellen Stellenanzeigen aus der Region. Erreichen Sie mit dem Job-Ticker die latent Job-Interessierten, die für Ihr Stellenagebot in Frage kommen, aber nicht aktiv auf einem Job-Portal nach Ihrer Anzeige suchen.</p>
              <h2>Leistungen &amp; Tarif</h2>
              <ul class="content-ul">
                <li>Platzierung auf kleinzeitung.at/wirtschaft, /karriere und auf den Regionalseiten.</li>
                <li>In einem Job-Ticker rotieren bis zu 20 Jobanzeigen</li>
                <li>Abbildung inkl. Firmenname und Logo, Job-Bezeichnung, Standort, Verlinkung Unternehmenswebsite</li>
                <li>Laufzeit: 1 Monat</li>
              </ul>

              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Job-Ticker</div>
                  <div class="col-lg-6 col-4 text-align-right">324,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="bild-box">
                <img src="@/assets/img/img-content/branchenloesungen/Devices-jobticker.png" alt="Job Ticker" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer.</div>
      </Tab>
      <Tab tabid="bildungberuf-tab2" :title="'Sponsored-Karriere Story '">
        <div class="portfolio-box">
          <div class="row">
            <div class="col-lg-12 margin-b-s">
              <h2>Sponsored Karriere-Story</h2>

              <p>Ihre Native Werbung im Karriereleiter Newsletter. Mit der Sponsored Story kreieren unsere Native Experten gemeinsam mit Ihnen lesenswerte, digitale Inhalte speziell für Ihre Zielgruppe. Ihre Botschaften überzeugen im redaktionellen Look &amp; Feel. Sie bleiben in positiver Erinnerung.</p>
            </div>

            <div class="col-lg-6 justify-content-center align-self-center">
              <h2>Leistungen &amp; Tarif</h2>
              <ul class="content-ul">
                <li>Ausführliches Briefing und Projektmanagement</li>
                <li>Content-Produktion im redaktionellen Look &amp; Feel (zwei Korrekturschleifen inklusive)</li>
                <li>Bewerbung auf kleinenzeitung.at, auf der Kleinen Zeitung-APP, im Karriere-Newsletter und in den sozialen Netzwerken</li>
                <li>Reporting der relevanten Kennzahlen</li>
                <li>Vorlaufzeit: 5 Werktage</li>
              </ul>
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">3.000 Leserinnen und Leser</div>
                  <div class="col-lg-6 col-4 text-align-right">4.985,00</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">1.500 Leserinnen und Leser</div>
                  <div class="col-lg-6 col-4 text-align-right">3.450,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 justify-content-center align-self-center">
              <div class="bild-box">
                <img src="@/assets/img/img-content/marktplaetze/sponsored-karriere-story.png" alt="Sponsored Karriere Story" class="img-fluid img-center" />
              </div>
              <br />
              <div class="bild-box">
                <img src="@/assets/img/img-content/marktplaetze/sponsored-karriere-story-v2.png" alt="Sponsored Karriere Story" class="img-fluid img-center" />
              </div>
            </div>
          </div>
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer.</div>
        </div>
      </Tab>
      <Tab tabid="bildungberuf-tab3" :title="'Unternehmensvorstellung #klzpräsentiert'">
        <div class="portfolio-box">
          <div class="row">
            <div class="col-lg-12">
              <h2>Ihre Unternehmensvorstellung<br />#klzpräsentiert</h2>
            </div>
            <div class="col-lg-6 order-lg-2">
              <div class="bild-box">
                <img src="@/assets/img/img-content/marktplaetze/unternehmensvorstellung-v2.jpg" alt="Unternehmensvorstellung" class="img-fluid" />
              </div>
            </div>
            <div class="col-lg-6">
              <br />
              <h2>Leistungen &amp; Tarif</h2>
              <ul class="content-ul">
                <li>Briefing (grobe, inhaltliche Ausrichtung - Zitat und Foto), Projektmanagement, Content-Produktion, Set-Up-Kosten, Bewerbung, Reporting</li>
                <li>Unternehmensvorstellung (1.500 bis 2.000 Zeichen, Person im Fokus)</li>
                <li>Info-Kasten beim Artikel mit Name, Kontaktdaten, Verlinkung zu aktuellen Jobangeboten (optional)</li>
                <li>Platzierung der Unternehmensvorstellung im Kleine Zeitung Karriere-Umfeld</li>
                <li>
                  Bewerbung der Unternehmensvorstellung auf allen Plattformen der Kleinen Zeitung
                  <ul class="content-ul">
                    <li>inklusive den sozialen Netzwerken (gesponsorte Kampagne über Facebook und Instagram, organische Instagram Story Slide (im vorgefertigten Design) mit Swipe Up zum Artikel und Verlinkung des Unternehmens)</li>
                    <li>und einem Native-Ad-Teaser im Karriereleiter Newsletter.</li>
                  </ul>
                </li>
              </ul>
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-8 col-8 text-align-left ">Unternehmensvorstellung (1.500 Leserinnen und Leser)</div>
                  <div class="col-lg-4 col-4 text-align-right">2.279,00</div>
                </div>
              </div>
            </div>
          </div>
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer.</div>
        </div>
      </Tab>

      <Tab tabid="bildungberuf-tab5" :title="'Job Display-Ads'">
        <div class="portfolio-box">
          <div class="row">
            <div class="col-lg-6 order-lg-2 justify-content-center align-self-center">
              <div class="bild-box">
                <img src="@/assets/img/img-content/marktplaetze/marktplaetze-job-ads-v2.jpg" alt="Job Pakete" class="img-fluid shadow" />
              </div>
            </div>
            <div class="col-lg-6 justify-content-center align-self-center">
              <h2>Job Display-Ads</h2>
              <p>Branchenspezifisches Content-Targeting über das gesamte Netzwerk rundet Ihre Raum-bzw. Zeilenanzeige ab. Erreichen Sie auch jene, die nicht aktiv suchen – und das für Sie mit minimalem Aufwand.</p>
            </div>
          </div>
        </div>

        <div class="row margin-50-0">
          <div class="col-lg-12">
            <h2>Wählen Sie Ihr Bewerbungs-Paket</h2>
            <br />
          </div>

          <div class="col-lg-6 justify-content-center ">
            <h3>Flexible</h3>
            <ul class="content-ul">
              <li>Werbemittel: <strong>im individuellen Design</strong></li>
              <li>Werbemittelerstellung <strong>kostenlos im Creative Generator</strong> oder <strong>Selbstanlieferung</strong></li>
              <li>Targeting: <strong>individuell wählbar</strong> (vorzugsweise regional)</li>
              <li><router-link :to="{ name: 'DisplayWerbung' }" class="content-link">Themen-Targeting zusätzlich wählbar</router-link></li>
              <li>Kampagnenerstellung: <strong>selbstständig</strong></li>
              <li>Kampagnenstart: <strong>frei wählbar</strong></li>
              <li>Laufzeit: <strong>flexibel</strong></li>
            </ul>

            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">Job-Ads <strong>FLEXIBLE</strong></div>
                <div class="col-lg-6 col-4 text-align-right">500,00</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center ">
            <h3>Premium</h3>
            <ul class="content-ul">
              <li>Werbemittel <strong>individuell nach Ihren Wünschen erstellt</strong></li>
              <li>Werbemittelerstellung: <strong>durch Design- Profis der Kleinen Zeitung</strong></li>
              <li>Targeting: <strong>individuell wählbar</strong> (vorzugsweise regional)</li>
              <li><router-link :to="{ name: 'DisplayWerbung' }" class="content-link">Themen-Targeting zusätzlich wählbar</router-link></li>
              <li>Kampagnenerstellung: <strong>serviciert</strong></li>
              <li>Kampagnenstart: <strong>frei wählbar</strong></li>
              <li>Laufzeit: <strong>4 Wochen</strong></li>
            </ul>

            <div class="tabelle-box custom-margin-top">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">Job-Ads <strong>PREMIUM</strong></div>
                <div class="col-lg-6 col-4 text-align-right">699,00</div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer.</div>
      </Tab>
      <!--
      <Tab tabid="bildungberuf-tab7" :title="'Karriereleiter Newsletter'">
        <div class="portfolio-box">
          <div class="row">
            <div class="col-lg-6">
              <div class="bild-box">
                <img src="@/assets/img/img-content/marktplaetze/d-karriere-newsletter.jpg" alt="Karriereleiter-Newsletter" class="img-fluid shadow" />
              </div>
            </div>
            <div class="col-lg-6">
              <h2>Karriereleiter-Newsletter</h2>
              <p><strong>Wir bringen weiter, was dich weiterbringt.</strong><br />Der Karriereleiter-Newsletter versorgt unsere Leser jeden Dienstag mit den wichtigsten Karriere-Themen, stellt Top-Arbeitgeber in Steiermark und Kärnten vor und liefert die Top Jobs aus der Region.</p>
              <p>Präsentieren Sie sich, Ihre Job-Angebote und Ihre Arbeit gegenüber der richtigen Zielgruppe und erreichen Sie somit Ihre zukünftigen BewerberInnen.</p>
              <div class="preis-box">
                <span>Tarif ab: <strong>150,00 </strong></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <h3>Tob Jobs der Woche</h3>
            <p>Ihre digitale Stellenanzeige im Karriereleiter Newsletter.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <div class="bild-box">
              <img src="@/assets/img/img-content/marktplaetze/bsp-top-jobs.png" alt="Bsp. Tob Jobs" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <strong>LEISTUNG</strong>
            <ul class="content-ul">
              <li>Platzierung Ihres Stellenangebotes im Karriereleiter-Newsletter</li>
              <li>Abbildung möglich in der Kategorie Steiermark oder Kärnten</li>
              <li>Abbildung inkl. Logo, Job-Titel, Firmenname, Standort, „Mehr erfahren“-Link zur Abbildung des Stellenangebotes auf der Unternehmensseite</li>
            </ul>
            <div class="preis-box">
              <span><strong>Tarif:</strong> 150,00</span>
            </div>
          </div>
        </div>
      </Tab>
      -->
      <Tab tabid="bildungberuf-tab6" :title="'willhaben.at'">
        <div class="portfolio-box">
          <div class="row">
            <div class="col-lg-6 justify-content-center align-self-center">
              <div class="bild-box">
                <img src="@/assets/img/img-content/marktplaetze/jobs-klz-willhaben.jpg" alt="Kleine Zeitung + Willhaben" class="img-fluid" />
              </div>
            </div>
            <div class="col-lg-6 justify-content-center align-self-center">
              <h2>willhaben.at</h2>
              <p>Profitieren Sie von der optimalen Kombination „Kleine Zeitung“ und „willhaben“. Mit der reichweitenstärksten Tageszeitung im Süden Österreichs und dem größten digitalen Marktplatz des Landes nutzen Sie das erfolgreichste Kommunikationsnetzwerk Österreichs.</p>
            </div>
          </div>
        </div>
        <br /><br />
        <h3>Ihre Werbemöglichkeiten als Add-On zur Kleinen Zeitung.</h3>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="tabelle-box alignItems">
              <div class="headline"><strong>Print Plus Stellenanzeige mit Logo</strong> - SelfService</div>
              <ul class="content-ul">
                <li>Anzeige selbst erstellen und verwalten</li>
                <li>Darstellung optimiert für alle Endgeräte</li>
                <li>Express-Vorreihung ( nach jeweils 7 Tagen)</li>
                <li>60 Tage Laufzeit</li>
              </ul>
              <div class="row head-row">
                <div class="col-lg-6 col-6 text-align-left">Jobanzeige</div>
                <div class="col-lg-6 col-6 text-align-right">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-6 text-align-left">1 Anzeige</div>
                <div class="col-lg-6 col-6 text-align-right">300,00</div>
              </div>
            </div>
            <!-- -->
            <div class="tabelle-box alignItems">
              <div class="headline"><strong>Print Plus zu Zeilenanzeige </strong> - SelfService</div>
              <ul class="content-ul">
                <li>Anzeige selbst erstellen und verwalten</li>
                <li>Darstellung optimiert für alle Endgeräte</li>
                <li>inkl. 14 –tägiger Vorreihung</li>
                <li>30 Tage Laufzeit</li>
              </ul>

              <div class="row head-row">
                <div class="col-lg-6 col-6 text-align-left">Laufzeit</div>
                <div class="col-lg-6 col-6 text-align-right">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-6 text-align-left">30 Tage</div>
                <div class="col-lg-6 col-6 text-align-right">150,00</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/digital/willhaben-stellenanzeige.jpg" alt="Willhaben Stellenanzeige" class="img-fluid img-s-75 img-center" />
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer. Keine Rabatte möglich.</div>
      </Tab>
    </TabGroup>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'Bildung Beruf',
  components: {
    //Modal,
    TabGroup,
    Tab,
    CollapsibleButton,
    Collapsible,
  },
});
</script>
